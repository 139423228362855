//////////////////////
/// REPORTS ///
////////////////////
#months-select{
    margin-right: 1rem;
}

.back-to-hours{
    cursor: pointer;
    margin-bottom: 2rem;

    img{
        transform: rotate(180deg);
    }

    p{
        font-size: 16px;
    }
}

.report-container{ 
    margin: 0 5rem;

    h3{ 
        margin-bottom: 1rem;
    }
}

.report-header{ 
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}


.total{
    &--header{ 
        border: 1px solid magenta;
        width: 33%;
    }

    &--data{
        width: 70%;
    }
}

.row-header{
    font-weight: 600;
}


.column{ 
    &--first{ 
        width: 40%;
    }

    &--second{
        width: 30%;
        text-align: center;
    }
    
}




@media only screen and (max-width: 767px){
    h2{ 
        margin-bottom: 1.5rem;
    }

    #export-pdf-btn{
        display: none;
    }

    .report-header{
        flex-direction: column;
        align-items: baseline;
    }
}