$grey: #4D4D4D;
$lt-grey: #aaaaaa;
$teal: #008B92;

#auth-form{ 
    border: 1px solid $lt-grey;
    gap: 1.5rem;
    margin-top: 1rem;
    padding: 3rem 4rem;
    height: 300px;
    width: 430px;

    input{ 
        border-radius: 0;
        padding: 0.75rem;
        width: 300px;
    }

    button{ 
        padding: 1rem;
        border-radius: 0;
        width: 300px;
    }

    div{
        gap: 1.5rem;    
    }

}


.form-container{ 
    height: 100%;
    gap: 1rem;

    img{
        width: 275px;
    }
}
 
#spinner{ 
width: 40px;
height: 40px;
border: 4px solid #ccc;
border-top: 4px solid $teal;
border-radius: 50%;
animation: spin 2s linear infinite;
}
  
@keyframes spin {
0% { transform: rotate(0deg); } /* Start position */
100% { transform: rotate(360deg); } /* End position (full rotation) */
}