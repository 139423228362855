//Variables
$teal: #008B92;
$grey: #4D4D4D;
$lt-grey: #aaaaaa;
$x-lt-grey:#ececec;
$lt-blue: #FAF7FB;
$orange: #ff6900;
$red: #ff0033;
$blue: #6699ff;


//Setup
*, *::after, *::before{ 
  box-sizing: border-box;
  margin: 0;
}

html, body{ 
  width: 100%;
  min-height: 100vh;
}

.App{
  height: 100vh;
}

//Typography
*{
  font-family: Poppins, sans-serif;
}

h2, p, span{ 
    color: $grey;
}

p{

  font-weight: 400;
  font-size: 13px;
  line-height: calc(28px + (28 - 28) * (100vw - 320px) / (1200 - 320));
}

//Global - Tables, Buttons, Labels, Inputs
button {
  border: 1px solid $orange;
  border-radius: 0.25rem;
  cursor: pointer;
  padding: 0.5rem;
}

label{
  display:block;
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

input, textarea, select{
  border: 1px solid $lt-grey;
  border-radius: 0.25rem;
  color: $grey;
  display: block;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: .4375rem .9375rem;
}

.btn{ 
  &--primary{ 
    background-color: $orange;
    color: #fff;

    &:hover{
      background-color: #fff;
      color: $orange;
    }
  }

  &--secondary{ 
    background-color: #fff;
    color: $orange;

    &:hover{
        background-color: $orange;
        color: #fff;
    }
  }

  &--delete{
    border: 1px solid $red;
    background-color:  $red;
    color: #fff;

    &:hover{
      background-color: #fff;
      color: $red;
    }
  }

  &--edit{ 
    border: 1px solid $blue;
    background-color:  $blue;
    color: #fff;

    &:hover{
      background-color: #fff;
      color: $blue;
    }
  }
}


//Utility
.dashboard{ 
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 1rem;
}

.flex-row{ 
  display: flex;
  align-items: center;
  gap: 1rem;

  &--center{
    justify-content: center;
  }

  &--space{ 
    justify-content: space-between;
  }

  &--end{
    justify-content: flex-end;
  }
}

.flex-column{ 
  display: flex;
  flex-direction: column;

  &--center{ 
    align-items: center;
    justify-content: center;
  }
}

.hidden{ 
  display: none;
}

.icon{
  cursor: pointer;
  
  &--small{
      height: 0.825rem;
      width: 0.825rem;
  }

  &--md{
      height: 1.125rem;
      width: 1.125rem;
  } 
  
  &--lg{
      height: 1.4rem;
      width: 1.4rem;
  }
}

