.inner-form{
    margin-top: 1rem;

    select, input, textarea{
        margin-bottom: 1rem;
    }

    select,input, textarea{
        width:100%;
    }

    textarea{
        height: 10rem;
        resize: none;
    }

    button{
        margin-right: 1rem;
    }

    div{
        width: 100%;
    }
}


@media only screen and (max-width: 991px){
    #add-another{ 
        display: none;
    }
}

@media only screen and (max-width: 767px){
    .inner-form{
        & :nth-child(3){
            flex-direction: column;
            align-items: baseline;

            & *{
                width: 100%;
            }
        }
    }
}
