$teal: #008B92;
$grey: #4D4D4D;
$lt-grey: #aaaaaa;
$x-lt-grey:#ececec;
$lt-blue: #FAF7FB;
$orange: #ff6900;


table{
    border: 1px solid $x-lt-grey;
    border-collapse: collapse;
    margin: 1rem 0 2rem 0;
    width: 100%;
}

tr, th, td{
    border-block: 1px solid $x-lt-grey;
    padding: 0.75rem 1rem;
    position: relative;
    text-align: left;
}

.companyName{
    min-width: 15rem;
    max-width: 20rem;
}

.date{
    width: 8rem;
}

.duration{
    width: 8rem;
    text-align: center;
}

th{
    background-color: $lt-blue;
}


//Table Header
.filter-dropdown{ 
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 1rem;


    border-radius: 0.5rem;
    text-align: left;
    overflow: scroll;
    width: 240px;

    position: absolute;
    right:0.2rem;
    top: 0.2rem;
    z-index: 1;

    p{
        margin: 0;
    }

    p, img{
        cursor: pointer;
    }

    div{
        padding-left: 0.75rem;
    }

    img{
        margin-right: 0.75rem;
    }
}

.sort{
    margin-left: 0.5rem;
}

.hidden-checkmark{
    opacity: 0;
}

#notes{
    display:none;
}

#notes-header{
    width: 40%;
}

//PAGINATION
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .page-item {
    margin: 0 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .active {
    text-decoration: underline;
  }
  
  .page-link {
    color: $grey;
    text-decoration: none;
  }
  
  .page-link:hover {
    cursor: pointer;
    text-decoration: underline;
  }