$teal: #008B92;
$blue: #009fa6;
$lt-grey: #aaaaaa;
$orange: #ff6900;

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 3;
}

.modal-inner{
    padding: 4.5rem 3rem 3rem 3rem !important;
    position:relative;
    gap: 1.5rem;
    min-width: 800px;
    max-width: 900px;
    max-height: 800px;
    overflow: scroll;
    
    h2{
        color: $teal;
    } 
}

.exit{
    cursor: pointer;
    opacity: 0.75;
    position: absolute;
    right: 3rem;
    top: 2.5rem;
}

.chevron-rotate{ 
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}

.accordion-section{ 
    border-bottom: 1px solid $lt-grey;
    padding-block: 1rem;

    h3{
        color: $blue;
    }

    p{
        font-size: 16px;
        padding-top: 1rem;
        line-height: 28px;
        text-align: justify;
    }
}

.accordion {
    padding-inline: 2rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out; // Adjust the timing as needed
  
    &--open {
      max-height: 1000px; // Set a value that is larger than the content's height
    }
}

.accordion-text{ 
    span{
        font-weight: bold;
        padding: 1.5rem 0 1rem 0;
    }
}