$orange: #ff6900;

#main-nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    padding: 2rem 5rem;

    .logo{
        width: 20rem;
    }
}

.user-dropdown{ 
    width: 15rem;

    p{
        cursor: pointer;

        &:hover{ 
            color: $orange;
        }
    }
    
}

@media only screen and (max-width:991px){
    .logout{ 
        position: static;
        padding: 0.5rem 1.5rem;
    }
}