#coaching-hours{ 
    width: 100%;
    padding: 1rem 5rem;
}

#button-container{ 
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}
