footer{ 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    background-color: #317279;
    margin-top: 7.5rem;
    padding-block: 2rem;
    text-align: center;

    img{
        width: 12%;
    }

    p, span{
        color: #fff;
        width: 40%;
    }

    span{
        font-size: calc(10px + (10 - 10) * (100vw - 320px) / (1200 - 320));
      }
}